<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered is-mobile">
          <div class="column is-4-desktop is-8-tablet is-10-mobile">
            <div class="box">
              <div class="columns is-centered is-mobile">
                <div class="column is-6">
                  <figure class="image"></figure>
                </div>
              </div>
              <template v-if="urlToken && !showError">
                <p class="title-recovery">
                  Ingresa tu nueva contraseña
                </p>
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="password"
                      placeholder="Contraseña"
                      v-model="password"
                      name="newPass"
                    >
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="password"
                      placeholder="Confirmar Contraseña"
                      v-model="confirmPassword"
                      name="confirmPass"
                    >
                  </div>
                </div>

                <!-- Mostrar los mensajes de validación faltantes -->
                <div v-if="validationErrors.length" class="has-text-danger">
                  <p v-for="(error, index) in validationErrors" :key="index">{{ error }}</p>
                </div>

                <div class="padding-area has-text-centered">
                  <button
                    class="button givu-button"
                    :disabled="!password || !confirmPassword"
                    :class="{ 'is-loading': loading }" @click="recoveryPass">Actualizar Contraseña</button>
                </div>
              </template>
              <template v-else>
                  <p class="title-recovery">
                    Ha ocurrido un error
                  </p>
                  <p>
                    Asegurate de ingresar a través del enlace que recibiste en tu correo electrónico.
                  </p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

const validationMessages = {
  min_length: 'Debe contener al menos 6 caracteres',
  max_length: 'No debe exceder los 15 caracteres',
  contains_number: 'Debe contener al menos un número',
  contains_uppercase: 'Debe contener al menos una letra mayúscula'
}

export default {
  name: 'recovery-password',
  data () {
    return {
      urlToken: this.$route.query.token,
      showError: false,
      loading: true,
      password: null,
      confirmPassword: null,
      validationErrors: []
    }
  },
  methods: {
    ...mapActions(['validateTokenRecovery', 'recoveryPassword', 'addTemporalNotification']),
    async recoveryPass () {
      this.validationErrors = []
      this.loading = true
      const password = this.password
      if (password !== this.confirmPassword) {
        this.loading = false
        this.addTemporalNotification({ type: 'danger', message: 'Las contraseñas no coinciden' })
      } else {
        this.recoveryPassword({ password, token: this.urlToken })
          .then(resp => {
            if (resp.success) {
              setTimeout(() => {
                location.replace('/login')
              }, 1500)
            } else {
              this.validationErrors = resp.criteria
                .filter(criteria => !criteria.passed)
                .map(criteria => validationMessages[criteria.name] || 'Error desconocido')
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  },
  beforeMount () {
    if (!this.urlToken) this.showError = true
    this.validateTokenRecovery(this.urlToken)
      .then(response => {
        if (!response.success) {
          this.showError = true
        }
        this.loading = false
      })
  }
}
</script>

<style scoped>
.section {
  background-color: #f7f7f7;
}
.container {
  padding-top: 3rem;
}
.title-recovery {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: #ef5a5e;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #F0F0F0;
}
.button {
  background-color: #ef5a5e;
  border-color: #ef5a5e;
  border-width: 1px;
  color: #fff;
}

.button:hover, .button:focus, .button:active {
  color: #fff;
  background-color: #ef5a5e;
  border-color: #ef5a5e;
}
</style>
